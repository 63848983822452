











































































import draggable from 'vuedraggable';
import { ref } from '@vue/composition-api';

import IndexTable from '@/components/IndexTable.vue';
import { MANAGE_TABLE_ITEMS } from '@/constants/manage-program';

import PCCard from '../components/PCCard.vue';
import ListView from '../components/ListView.vue';

export default {
  name: 'Manage',
  components: {
    'pc-card': PCCard,
    draggable,
    ListView,
    IndexTable
  },
  setup() {
    const gridList = ref(false);
    const listViewcheck = ref(true);
    return { items: ref(MANAGE_TABLE_ITEMS), listViewcheck, gridList };
  },

  computed: {
    dragOptions() {
      return {
        animation: 150,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
        chosenClass: 'sortable-chosen',
        dragClass: 'sortable-drag'
      };
    }
  }
};
