





























import { ref } from '@vue/composition-api';
import draggable from 'vuedraggable';
import IndexTable from '@/components/IndexTable.vue';
import { LIST_VIEW_HEADERS, LIST_VIEW_ITEMS } from '@/constants/manage-program';

export default {
  name: 'ListView',
  components: {
    IndexTable,
    draggable
  },

  setup() {
    return { header: ref(LIST_VIEW_HEADERS), items: ref(LIST_VIEW_ITEMS) };
  },
  computed: {
    dragOptions() {
      return {
        animation: 150,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
        chosenClass: 'sortable-chosen',
        dragClass: 'sortable-drag'
      };
    }
  }
};
