

























































import {} from '@vue/composition-api';

export default {
  name: 'PCCard',
  setup() {
    return {};
  }
};
